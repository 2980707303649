import axios from 'axios';
import { BASENAME } from '../common/constants';

const getTranslatedText = async (text, languages) => {
  try {
    return await axios.post(`${BASENAME}/translate?api-version=3.0${languages}`, text, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    console.error(e);
    return {};
  }
};

export default getTranslatedText;
