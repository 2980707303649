import { PAGE_COUNT } from '../constants/app-constants';

const getFilteredMapData = ({
  filterStatus, value, group, data,
}) => {
  let count = 0;
  if (Object.keys(filterStatus).length) {
    const countZone = filterStatus[group];
    count = countZone.items[value];
  }
  data.push({
    group, value, count,
  });
};
const groupArray = ({
  filterCriteria = {}, filterStatus = {}, name = '',
}) => {
  const data = [];
  if (Object.keys(filterStatus).length && Array.isArray(filterCriteria)) {
    filterCriteria.forEach((firstLevelCriteria) => {
      getFilteredMapData({
        filterStatus,
        name,
        value: firstLevelCriteria.value,
        group: firstLevelCriteria.group,
        data,
      });
    });
  } else {
    Object.keys(filterCriteria).forEach((firstLevelCriteria) => {
      filterCriteria[firstLevelCriteria].forEach((secondLevelCriteria) => {
        getFilteredMapData({
          filterStatus,
          name,
          value: secondLevelCriteria,
          group: firstLevelCriteria,
          data,
        });
      });
    });
  }
  return data;
};
const getAppliedData = ({
  filterCriteria, name, appliedFilter, isGroup = true,
  selectedFilter,
}) => {
  if (Array.isArray(filterCriteria)) {
    filterCriteria.forEach((item) => {
      const isSelected = appliedFilter[name].some((s) => (s === item.value) || (s && s.value === item.value));
      if (isSelected && item.value && isGroup) {
        selectedFilter[name].push({ value: item.value, group: item.group, count: item.count });
      }
      if (isSelected && item.value && !isGroup) {
        selectedFilter[name].push({ value: item.value, count: item.count });
      }
    });
  }
};
const getAppliedFilter = (props) => {
  const {
    filters = [],
    appliedFilter = {
      search: '', locations: [], services: [], solutions: [], languages: [],
    },
  } = props || {};
  const selectedFilter = {
    locations: [], services: [], solutions: [], languages: [], search: '',
  };
  getAppliedData({
    filterCriteria: filters?.locations || [], name: 'locations', appliedFilter, selectedFilter,
  });
  getAppliedData({
    filterCriteria: filters?.services || [], name: 'services', appliedFilter, selectedFilter,
  });
  getAppliedData({
    filterCriteria: filters?.solutions || [], name: 'solutions', appliedFilter, selectedFilter,
  });
  getAppliedData({
    filterCriteria: filters?.languages || [], name: 'languages', appliedFilter, selectedFilter, isGroup: false,
  });
  selectedFilter.search = appliedFilter.search || '';
  selectedFilter.pageCount = appliedFilter.pageCount;
  selectedFilter.pageNumber = appliedFilter.pageNumber;
  return selectedFilter;
};
const getFormattedGroupArray = (object) => {
  const {
    filters = {}, filterStatus = {},
  } = object || {};
  const filterObject = {};
  filterObject.locations = groupArray({
    filterCriteria: filters.locations || {}, filterStatus: filterStatus?.locations || {}, name: 'locations',
  });
  filterObject.services = groupArray({
    filterCriteria: filters?.services || {}, filterStatus: filterStatus?.services || {}, name: 'services',
  });
  filterObject.solutions = groupArray({
    filterCriteria: filters?.solutions || {}, filterStatus: filterStatus?.solutions || {}, name: 'solutions',
  });
  filterObject.languages = [];
  if (filters && filters.languages && filters.languages.length) {
    filters.languages.forEach((language) => {
      let count = 0; let value = '';
      if (filterStatus.languages && Object.keys(filterStatus.languages).length) {
        if (typeof language === 'object') {
          value = language.value;
        } else if (typeof language === 'string') {
          value = language;
        }
        count = filterStatus.languages[value];
      } else {
        value = language;
      }
      filterObject.languages.push({ value, count });
    });
  }
  return { filters: filterObject };
};

const getData = (data, name) => data.map((item) => item[name]).toString();

const getSelectedFilter = (filter) => {
  const {
    languages = [], locations = [], services = [], solutions = [], pageCount, pageNumber, search = '',
  } = filter;
  const lang = ((languages.length) ? getData(languages, 'value') : '');
  const location = ((locations.length) ? getData(locations, 'value') : '');
  const solution = ((solutions.length) ? getData(solutions, 'value') : '');
  const service = ((services.length) ? getData(services, 'value') : '');
  return {
    lang, location, solution, service, pageCount, pageNumber, search,
  };
};
const isFilterSelected = (filter) => {
  const {
    lang = '', location = '', solution = '', service = '', search = '',
  } = getSelectedFilter(filter);
  return lang || location || solution || service || search;
};

// eslint-disable-next-line consistent-return
const getSRPSearchPayLoad = (filter, query = false) => {
  const {
    lang = '', location = '', solution = '', service = '', pageCount = PAGE_COUNT, pageNumber = 1, search = '',
  } = getSelectedFilter(filter);
  const param = `${search ? `&search=${encodeURIComponent(search)}` : ''}${location && `&locations=${location}`}${lang && `&languages=${lang}`}${solution && `&solutions=${encodeURIComponent(solution)}`}${service && `&services=${service}`}`;
  if (query) {
    return param.trim() ? `?${param}`.replace('?&', '?') : '';
  } if (!query) {
    return `page_count=${pageCount}&page_no=${pageNumber}${param}`;
  }
};
const getSRPURLSearchParams = (queryParam) => {
  const getDataArray = (data) => data.split(',') || [];
  const locations = queryParam.get('locations');
  const languages = queryParam.get('languages');
  const services = queryParam.get('services');
  const solutions = queryParam.get('solutions');
  const filters = {
    languages: languages ? getDataArray(languages) : [],
    locations: locations ? getDataArray(locations) : [],
    solutions: solutions ? getDataArray(solutions) : [],
    services: services ? getDataArray(services) : [],
    search: queryParam.get('search') || '',
  };
  return filters;
};
export {
  getAppliedFilter,
  getFormattedGroupArray,
  getSRPSearchPayLoad,
  getSelectedFilter,
  isFilterSelected,
  getSRPURLSearchParams,
};
