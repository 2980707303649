const ProfileStateInterface = {
  loading: true,
  profileDetails: {},
  partnerDetails: {},
  translationsLoaded: false,
  errors: {
    error: false,
  },
  env: 'dev',
};
module.exports = {
  ProfileStateInterface,
};
