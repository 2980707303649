const { PAGE_COUNT } = require('../../constants/app-constants');

const SRPStateInterface = {
  filters: {},
  loading: true,
  appliedFilter: {
    languages: [], locations: [], solutions: [], services: [], pageCount: PAGE_COUNT, search: '', pageNumber: 1,
  },
  listPartners: {
    partners: [],
    current_page: 1,
    next_page: 2,
    per_page: PAGE_COUNT,
    page_no: 1,
    total_count: 0,
    total_pages: 1,
  },
  srpErrors: {
    filterError: false,
    filterStatusError: false,
    partnersError: false,
  },
  env: 'dev',
};
module.exports = {
  SRPStateInterface,
};
