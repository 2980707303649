const {
  LANDSCAPE_CARD_PREVIEW_LENGTH,
  PORTRAIT_CARD_PREVIEW_LENGTH,
  MOBILE_CARD_PREVIEW_LENGTH,
  DESKTOP_CARD_PREVIEW_LENGTH,
  NUMBERS,
} = require('../constants/app-constants');

const isMobileDevice = () => typeof window !== 'undefined'
    && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
    );

const getCradPreviewLength = () => {
  if (
    typeof window !== 'undefined'
        && window?.screen?.availWidth >= NUMBERS.SEVEN_HUNDRED_SIXTY_EIGHT
        && window?.screen?.availWidth <= NUMBERS.ONE_THOUSAND_TWENTY_FOUR
  ) {
    return LANDSCAPE_CARD_PREVIEW_LENGTH;
  }
  if (
    typeof window !== 'undefined'
        && window?.screen?.availWidth >= NUMBERS.SIX_HUNDRED_FOURTY
        && window?.screen?.availWidth <= NUMBERS.SEVEN_HUNDRED_SIXTY_EIGHT
  ) {
    return PORTRAIT_CARD_PREVIEW_LENGTH;
  }
  if (!isMobileDevice()) {
    return DESKTOP_CARD_PREVIEW_LENGTH;
  }
  return MOBILE_CARD_PREVIEW_LENGTH;
};
const getKebabCase = (name, displayName = '') => {
  let removeSpclCharacter = name?.replace(/[^\w\s]/gi, '');
  if (!removeSpclCharacter && displayName) {
    removeSpclCharacter = displayName?.replace(/[^\w\s]/gi, '');
  }
  return removeSpclCharacter?.toLowerCase()?.split(' ').join('-');
};

const makeTranslatedData = (apiTranslations, requestPayload, languages) => {
  const newTranslations = {};
  languages.forEach((lang) => {
    const languageKey = lang.split('-')[0];
    apiTranslations.forEach((translation, index) => {
      const englishKey = requestPayload[index]?.Text;
      const translationElement = translation.translations.find((element) => languageKey === element.to || (lang === 'zh-HK' && element.to === 'yue') || (lang === 'zh-CN' && element.to === 'zh-Hans'));
      if (englishKey && translationElement) {
        if (!newTranslations[lang]) {
          newTranslations[lang] = {};
        }
        newTranslations[lang][englishKey] = translationElement.text;
      }
    });
  });
  return newTranslations;
};

module.exports = {
  getKebabCase,
  isMobileDevice,
  getCradPreviewLength,
  makeTranslatedData,
};
